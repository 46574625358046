<template lang="pug">
.accept.app.flex-row.align-items-center
  .container
    .justify-content-center.w-full
      .card.p-4.h-500.box-shadow
        .card-body.w-full.text-center.flex
          div.font-size-50.m-auto(v-if = '!checkQuery')
            i.fas.fa-exclamation-circle.text-danger.font-size-120
            div {{ "You have an incorrect link" }}
          div.m-auto(v-else)
            div.loader(v-if = 'loading')
            div(v-else)
              i.fas.fa-exclamation-circle.text-danger.font-size-120(v-if = 'link')
              div.font-size-50 {{ link }}
</template>


<script>
import { mapActions } from 'vuex'
export default {
  name: 'accept',

  i18nOptions: { namespaces: 'accept' },

  data: () => ({
    link: null,
    loading: true
  }),
  computed: {
    checkQuery () {
      if (!this.$route.params.id || !this.$route.query.taskId) {
        return false
      }
      return true
    }
  },
  async created () {
  },
  async mounted () {
    if (this.checkQuery) {
      const data = await this.acceptOffer({ id: this.$route.params.id, taskId: this.$route.query.taskId })
      if (data.name === 'Error') {
        if (data.response?.data?.message) {
          this.link = data.response.data.message
        } else {
          this.link = 'Oops, something went wrong'
        }
      }
      if (data.name !== 'Error' && data) {
        location.href = data
      }
    }
    this.loading = false
  },
  methods: {
    ...mapActions('accept', ['acceptOffer'])
  }
}
</script>


<style scoped lang="scss">
.btn-group, .btn-group a {
  flex-wrap: nowrap;
}
.h-500 {
  height: 500px;
}
.text-center {
  text-align: center;
}
.m-t-20 {
  margin-top: 20%;
}
.font-size-50 {
  font-size: 50px;
}
.font-size-30 {
  font-size: 30px;
}
.box-shadow {
  box-shadow: 0px 0px 20px;
  border-radius:2px;
}
.font-size-120 {
  font-size: 120px;
}
.flex {
  display: flex;
}
.m-auto {
  margin: auto;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.loader,
.loader:before,
.loader:after {
  background: #353131;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #353131;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
